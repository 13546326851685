import mainSelect from '@/components/main-select/index.vue'
import secondaryButton from '@/components/buttons/secondary-button/index.vue'
import secondarySelect from '@/components/secondary-select/index.vue'
import accordion from '@/modules/profile/user/pages/bonus/accordion/index.vue'
import {mapGetters, mapMutations, mapActions} from "vuex";


export default {
  name: "bonus",
  data() {
    return {
      blurEffect: false,
      onceDiscountSum: [
        {
          value: '0-1000',
          percent: '2%'
        },
        {
          value: '1001 - 3000',
          percent: '3%'
        },
        {
          value: '3001 - 5000',
          percent: '5%'
        },
        {
          value: '5001 - 8000',
          percent: '7%'
        },
        {
          value: '8001 - 12000',
          percent: '10%'
        },
      ],
      onceDiscountCollecting: [
        {
          value: '0-4000',
          percent: '2%'
        },
        {
          value: '4001 - 6000',
          percent: '3%'
        },
        {
          value: '6001 - 8000',
          percent: '4%'
        },
        {
          value: '8001 - 10000',
          percent: '6%'
        },
        {
          value: '10001 - 12000',
          percent: '8%'
        },
        {
          value: '12001 - 15000',
          percent: '10%'
        },
        {
          value: '15001 и свыше',
          percent: '12%'
        },
      ],
      selectedDiscountCollecting: {
        value: '0-4000',
        percent: '2%'
      },
      selectedDiscount: {
        value: '0-1000',
        percent: '2%'
      },
      days: [],
      payload: {
        sum: 0,
        present: '',
      },
      year: '',
      month: '',
      date: '',
      professional: localStorage.getItem('professional'),
      promocodeValue: '',
      bindPromocode: '',
      showPromts: false,
      showWarning: false,
      showPromptsPromo: false

    }
  },


  methods: {
    ...mapMutations({
      changePopupUserCosmetologist: 'user/CHANGE_POPUP_USER_COSMETOLOGIST',
      changeShowAddPromo: 'user/SHOW_POPUP_ADD_PROMO',
      changeShowErrorPromo: 'user/SHOW_POPUP_ERROR_PROMO',
      changeShowchangePromo: 'user/SHOW_CHANGE_PROMOCODE',
      changeShowGenerate: 'user/SHOW_GENERATE_PROMO'
    }),
    ...mapActions({
      getDataBonuses: 'user/DATE_BONUSES',
      generatePromo: 'user/GENERATE_PROMO',
      bindPromo: 'user/BIND_PROMO',
      getReferrals: 'auth/GET_REFERRALS'
    }),
    blurElements() {
      this.blurEffect = !this.blurEffect
    },

    showWarnings() {
      this.showWarning = true
    },
    hideWarnings() {
      this.showWarning = false
    },
    showPrompt() {
      this.showPromts = true
    },
    hidePrompt() {
      this.showPromts = false
    },
    showPromptPromo() {
      this.showPromptsPromo = true
    },
    hidePromptPromo() {
      this.showPromptsPromo = false
    },
    sendPromo() {
      this.bindPromo({promo_code: this.bindPromocode}).then(() => {
        this.$toasted.success(this.$t('promoAddSuccess'));
        this.changeShowAddPromo(true)
      }).catch(() => {
        this.changeShowErrorPromo(true)
        this.$toasted.error(this.$t('promoAddError'));
      })
    },
    sendPromocode() {
      this.generatePromo({promo_code: this.promocodeValue})
      this.changeShowGenerate(true)
    },
    onDayClick(day) {

      const idx = this.days.findIndex(d => d.id === day.id);
      if (this.days.length === 0) {
        this.days.push({
          id: day.id,
        });

      } else {
        this.days.splice(idx, 1);
        this.days.push({
          id: day.id,
        });
      }

      let date = this.days[0].id
      this.year = date.slice(0, 4)
      this.month = date.slice(5, 7)
      this.day = date.slice(8, 10)

      parseInt(this.month.slice(0, 1)) === 0 ? this.month = parseInt(this.month.slice(1)) : parseInt(this.month)
      parseInt(this.day.slice(0, 1)) === 0 ? this.day = this.day.slice(1) : this.day
      this.month = parseInt(this.month)

      let locale = this.$route.params.lang

      switch (locale) {

        case 'ua':
          if (this.month === 1) {
            this.month = 'січня'
          }
          if (this.month === 2) {
            this.month = 'лютого'
          }
          if (this.month === 3) {
            this.month = 'березня'
          }
          if (this.month === 4) {
            this.month = 'квітня'
          }
          if (this.month === 5) {
            this.month = 'травня'
          }
          if (this.month === 6) {
            this.month = 'червня'
          }
          if (this.month === 7) {
            this.month = 'липня'
          }
          if (this.month === 8) {
            this.month = 'серпня'
          }
          if (this.month === 9) {
            this.month = 'вересня'
          }
          if (this.month === 10) {
            this.month = 'жовтня'
          }
          if (this.month === 11) {
            this.month = 'листопада'
          }
          if (this.month === 12) {
            this.month = 'грудня'
          }
        case'ru':
          if (this.month === 1) {
            this.month = 'января'
          }
          if (this.month === 2) {
            this.month = 'февраля'
          }
          if (this.month === 3) {
            this.month = 'марта'
          }
          if (this.month === 4) {
            this.month = 'апреля'
          }
          if (this.month === 5) {
            this.month = 'мая'
          }
          if (this.month === 6) {
            this.month = 'июня'
          }
          if (this.month === 7) {
            this.month = 'июля'
          }
          if (this.month === 8) {
            this.month = 'авгуса'
          }
          if (this.month === 9) {
            this.month = 'сентября'
          }
          if (this.month === 10) {
            this.month = 'октября'
          }
          if (this.month === 11) {
            this.month = 'ноября'
          }
          if (this.month === 12) {
            this.month = 'декабря'
          }

      }
      this.getDataBonuses({date: this.days[0].id})
    },
  },

  components: {
    mainSelect,
    secondarySelect,
    secondaryButton,
    accordion
  },
  computed: {
    ...mapGetters({
      showPopupCosmetologist: 'user/changeCosmetologist',
      dateBonus: 'user/dateBonuses',
      getPromocode: 'user/generatedPromo',
      user: `profile/user`,
      referrals: 'auth/referrals'
    }),
    dates() {
      return this.days.map(day => day.date);
    },

    attributes() {
      return this.dates.map(date => ({
        // highlight: true,
        // dates: date,
        dates: new Date(),
        excludeDates: null,
        order: 0

      }));
    },
    sum() {
      const arr = []
      for (let i = 1; i < 10; i++) {
        arr.push(i + ' грн')
      }
      return arr
    },
    present() {
      const arr = []
      for (let i = 1; i < 11; i++) {
        if (i % 2 === 0) {
          arr.push(i + ' %')
        }
      }
      return arr
    }
  },
  created() {
    if (this.professional) {
      this.getReferrals()
    }
  }
}

