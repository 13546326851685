import {VueSlideToggle} from 'vue-slide-toggle';
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "current-orders",
    components: {
        VueSlideToggle
    },
    props: ['order', 'blur'],
    data() {
        return {
            openItem: false,
            blurElement: false,
        };
    },
    computed: {
        ...mapGetters({
            repeatOrder: 'order/repeatOrder',
        }),
    },
    methods: {
        ...mapActions({
            fetchRepeatOrder: 'order/REPEAT_ORDER',
        }),
        ...mapMutations({
            resetBasket: 'basket/RESET_BASKET',
            addToBasket: 'basket/ADD_ITEM_TO_BASKET'
        }),
        repeat(id) {
            this.fetchRepeatOrder(id).then(() => {
                this.resetBasket()

                for (let i in this.repeatOrder) {

                    this.addToBasket({
                        select_count: this.repeatOrder[i].count,
                        select_price: this.repeatOrder[i].weight.price,
                        select_volume: this.repeatOrder[i].weight,
                        ...this.repeatOrder[i]
                    })
                }

                this.$router.push({name: 'buy-flow'}).catch(() => {
                })
                setTimeout(() => {
                    this.colorUnSet()
                }, 20)
            })
        },
        colorSet() {
            // document.getElementById("bg_wrapper").style.visibility = 'visible'
            // this.$refs.bg_slider.style.zIndex = 25;
            // this.$refs.bg_slider.style.position = 'sticky';
            // this.$refs.bg_slider.style.backgroundColor = '#fff'
            // this.$refs.bg_slider.style.boxShadow = '0px 0px 27px 20px rgba(34, 60, 80, 0.2)'
            // document.getElementById("header").style.position = 'sticky';
            // document.getElementById("breadcrumbs_wrap").style.paddingTop = '8px'
        },
        colorUnSet() {
            document.getElementById("bg_wrapper").style.visibility = 'hidden'
            this.$refs.bg_slider.style = 'z-index: auto;'
            document.getElementById("breadcrumbs_wrap").style.paddingTop = '120px'
            document.getElementById("header").style.position = 'fixed';
        }
    }

};
